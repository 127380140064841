/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation();

        // headroom style function
        $(window).scroll(function () {
          if ($(this).scrollTop() > 1) {
            $('#menu-items').removeClass('is-top');
            $('#menu-items').addClass('not-top');
          }
          if ($(this).scrollTop() === 0) {
            $('#menu-items').removeClass('not-top');
            $('#menu-items').addClass('is-top');
          }
        });

        // all menu toggle buttons
        $('button.x-toggle').click(function() {
          $(this).toggleClass('active');
        });

        // medium aux nav toggle
        $('#menu-items button.aux-toggle').click(function() {
          $('#aux-menu').toggleClass('active');
        });

        // nav search toggle
        $('#menu-items li.search a').click(function(event) {
          event.preventDefault();
          $(this).parent('li.search').toggleClass('active');
        });

        // if page has no hero
        if (!$('main.main > .hero').length) {
          $('body').addClass('no-hero');
        }

        // wysiwyg section image wraps - for jagged edge
        $('.wysiwyg-section img').each(function(index, el) {
          $(this).parent('p').addClass('has-image');
        });

        // change search field placeholder text
        $('.search-form input.search-field').attr('placeholder', 'Recipes & Classes');

        // hide date filter on main calendar page
        if($('#tribe-events-content.tribe-events-month').length > 0) {
          $('#tribe_events_filters_form #tribe_events_filter_item_dayofweek').css('display', 'none');
        }

        // day view notice
        if($('#tribe-events-content.tribe-events-day').length > 0) {
          if(!$.trim($('#tribe-events-day.tribe-events-loop .tribe-events-day-time-slot').html()).length) {
            $('#tribe-events-day.tribe-events-loop').before('<div class="tribe-events-notices"><ul><li>Sorry, no events found. Please try another day.</li></ul></div>');
          }
        }

        // filter wizardry
        function defaultSwapper() {
          // class type
          var classTypeText = jQuery('#tribe_events_filter_item_eventcategory .select2-default span.select2-chosen').text();
          // if default, change
          if(classTypeText === 'Select') {
            jQuery('#tribe_events_filter_item_eventcategory .select2-default span.select2-chosen').text('- Class Type -');
          }
          // venue
          var venueText = jQuery('#tribe_events_filter_item_venues .select2-default span.select2-chosen').text();
          // if default, change
          if(venueText === 'Select') {
            jQuery('#tribe_events_filter_item_venues .select2-default span.select2-chosen').text('- Location -');
          }
        }

        // filter wizardry calls
        jQuery(window).bind('load', function() {
          // on tablet and up
          if((jQuery('#tribe_events_filters_form').length > 0) && jQuery('#mobile-tester').is(':hidden')) {
            // init call
            defaultSwapper();
            // call after ajax requests
            jQuery(document).ajaxComplete(function() {
              defaultSwapper();
            });
            jQuery(document).ajaxError(function() {
              defaultSwapper();
            });
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }   
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // archive listing
    'recipes_library': {
      init: function() {
        $('#wpupg-grid-recipes-grid-filter').click(function() {
          $('#wpupg-grid-recipes-grid .grid-item').each(function(index, el) {
            if ($(this).height() === 0) {
              $(this).css('display', 'none');
            } else {
              $(this).css('display', 'inline-block');
            }
          });
        });
      },
    },
    // careers page
    'careers': {
      init: function() {
        $('.accordion .accordion-item .accordion-content a[href="#gform_wrapper_3"]').click(function(event) {
          event.preventDefault();
          var thisOffset = $(this).offset().top;
          var formOffset = ($('.gform_wrapper#gform_wrapper_3').offset().top) - 50;
          var scrollDistance = formOffset - thisOffset;
          var scrollDestination = thisOffset + scrollDistance;
          $('html,body').animate({
            scrollTop: scrollDestination
          }, 1000);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
